import "./App.css";
import EmailSignature from "./EmailSignature";
import ReactDOMServer from "react-dom/server";
import {useState} from "react";

function toBinary(string) {
    const codeUnits = new Uint16Array(string.length);
    for (let i = 0; i < codeUnits.length; i++) {
        codeUnits[i] = string.charCodeAt(i);
    }
    return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
}

function fromBinary(encoded) {
    const binary = atob(encoded);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

export default function App({serializedState}) {

    let defaultState;
    let showSignatureOnly = false;

    try {
        defaultState = JSON.parse(fromBinary(serializedState));
        showSignatureOnly = true;
    }
    catch {
        defaultState = {
            nameAndSurname: "Meno Priezvisko, SDB",
            position: "Delegát spoločenskej komunikácie a médií",
            positionEnglish: "Social Communication and media delegate",
            mobile: "+421 123 456 789",
            address: "Miletičova 7, 821 08 Bratislava"
        };
    }


    const [state, setState] = useState(defaultState);

    const [copied, setCopied] = useState(false);
    let copyTimeout;

    const fields = [
        {
            name: "nameAndSurname",
            label: "Meno a priezvisko",
        },
        {
            name: "position",
            label: "Pozícia",
        },
        {
            name: "positionEnglish",
            label: "Pozícia (po anglicky)",
        },
        {
            name: "mobile",
            label: "Mobil",
            hint: "Použi medzinárodný formát. Napríklad: +421 123 456 789",
        },
        {
            name: "address",
            label: "Adresa",
            hint: "Použi formát Ulica číslo, PSČ Obec. Napríklad: Miletičova 7, 821 08 Bratislava",
        },
    ];

    const Signature = (
        <EmailSignature {...state}/>
    );
    const source = ReactDOMServer.renderToStaticMarkup(Signature);

    function handleInput(event) {
        setState({...state, [event.target.name]: event.target.value});
    }

    function copyToClipboard() {
        navigator.clipboard.writeText(source);
        setCopied(true);
        clearTimeout(copyTimeout);
        copyTimeout = setTimeout(() => setCopied(false), 1000);
    }

    return showSignatureOnly ? Signature : (
        <div className="app">
            <h1>Generátor podpisu emailu</h1>
            <h2>Zadaj svoje údaje</h2>
            {fields.map((field, index) => (
                <div key={index} className="form-group">
                    <label htmlFor={field.name} className="form-label">
                        {field.label}:
                    </label>
                    {field.hint && <div className="form-help">{field.hint}</div>}
                    <input
                        type="text"
                        id={field.name}
                        name={field.name}
                        value={state[field.name]}
                        className="form-control"
                        onInput={handleInput}
                    />
                </div>
            ))}

            <h2>Náhľad</h2>
            <div className="preview">{Signature}</div>

            <h2>Kód</h2>
            <div className="code">
                <button onClick={copyToClipboard}>Skopírovať do schránky</button>
                <a href={`?${toBinary(JSON.stringify(state))}`} className="button" target="_blank" rel="noreferrer" style={{
                    marginLeft: 20,
                    backgroundColor: 'white',
                    color: 'black'
                }}>Náhľad emailu v novom tabe</a>

                <div style={{
                    paddingTop: 10,
                    visibility: copied ? 'visible' : 'hidden'
                }}>Kód bol skopírovaný do schránky
                </div>

                <textarea
                    style={{
                        width: "100%",
                        height: "100px",
                        marginTop: '1rem'
                    }}
                    value={source}
                    readOnly={true}
                />
            </div>
        </div>
    );
}
